<template>
  <FocusTrap>
  <div class="card">

    <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 12px;" >
      <h5 class="card-title">Company Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p/>
      <p/>
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input class="form-control" v-if="company" v-model="company.name" autofocus required/>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Street:</label>
          <div class="col-md-10">
            <textarea class="form-control" v-if="company" v-model="company.street" rows="3"/>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">City:</label>
          <div class="col-md-4">
            <input class="form-control" v-if="company" v-model="company.city" rows="3"/>
          </div>

          <label class="col-md-1 col-form-label">PIN:</label>
          <div class="col-md-5">
            <input  class="form-control" v-if="company" v-model="company.pin" />
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Tel:</label>
          <div class="col-md-4">
            <input class="form-control" v-model="company.tel"/>
          </div>

          <label class="col-md-2 col-form-label">Mobile:</label>
          <div class="col-md-4">
            <input class="form-control" v-model="company.mobile"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Email:</label>
          <div class="col-md-4">
            <input class="form-control" v-model="company.email"/>
          </div>

          <label class="col-md-2 col-form-label">Web:</label>
          <div class="col-md-4">
            <input class="form-control" v-model="company.web"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">GSTIN:</label>
          <div class="col-md-10">
            <input class="form-control" v-if="company" v-model="company.gstin" maxlength="15" required/>
          </div>
        </div>


      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'CompanyForm',
    data () {
      return {
        company: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""}'),
      }
    },
    props: {
      myitem: {
        type: Object,
        required: false,
        default: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""}')
      }
    },
    beforeMount () {
      this.company = this.myitem; // save props data to itself's data and deal with tms
    },
    component: {

    },
    created () {
      this.$data.company = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""}')
    },
    mounted () {
      const self = this;
      $('.form-control-select2').select2()
    },
    methods: {
      closeWindow(){
        this.$emit('company_window_closed');
      },
      clear(){
        this.$data.company = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""}')
      },
      saveDocument(){
        const self = this;

        if(self.$data.company.name.trim().length < 1){
          alert("Invalid Name");
          return
        }


        const requestOptions = {
          method:  (self.$data.company.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.company)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}api/company`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500  });
            self.$emit('company_saved', resp.data);
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
  input:focus {
    padding: 5px;
    background-color: lightgoldenrodyellow !important;
  }

  select:focus {
    padding: 5px;
    background-color: lightgoldenrodyellow;
  }

  textarea:focus {
    padding-left: 5px;
    background-color: lightgoldenrodyellow;
  }

  button:focus {
    box-shadow: none;
    background-color: lightgoldenrodyellow;
  }
</style>
